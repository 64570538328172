/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.learn-more-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.learn-more-button {
  width: 12rem;
  height: auto;
}

.learn-more-button .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}

.learn-more-button .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.learn-more-button .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.learn-more-button .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.learn-more-button .learn-more-button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 2.5rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
}

.learn-more-button:hover .circle {
  width: 100%;
}

.learn-more-button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.learn-more-button:hover .learn-more-button-text {
  color: #fff;
}

/* From Uiverse.io by Creatlydev */
.rolling-button {
  cursor: pointer;
  border: none;
  background: #000;
  color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: grid;
  place-content: center;
  transition: transform 200ms;
  font-weight: 600;
}

.button__text {
  position: absolute;
  inset: 0;
  animation: text-rotation 8s linear infinite;

  > span {
    position: absolute;
    inset: 7px;
  }
}

.button__circle {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #fff;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__icon--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.rolling-button:hover {
  background: #af982f;
  transform: scale(1.05);
}

.rolling-button:hover .button__icon {
  color: #af982f;
}

.rolling-button:hover .button__icon:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.rolling-button:hover .button__icon--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

@keyframes text-rotation {
  to {
    rotate: 360deg;
  }
}

.carousel-container {
  width: 80%;
  background-color: #f3f4f6; /* bg-gray-100 */
  border-radius: 0.5rem;
  padding: 1.25rem 1.25rem 2.5rem;
  overflow: hidden;
  position: relative;
  height: 400px; /* Set a fixed height for consistency */
}

.carousel-image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  transition: opacity 1s ease, transform 1s ease; /* Transition for both fade and slide */
  opacity: 1;
  transform: translateX(0);
}

.carousel-image-wrapper.exit {
  opacity: 0; /* Fade out effect */
  transform: translateX(-100%); /* Slide out to the left */
}

.carousel-image-wrapper.enter {
  opacity: 0; /* Start off invisible */
  transform: translateX(100%); /* Start off-screen to the right */
}

.carousel-image-wrapper.enter.active {
  opacity: 1; /* Fade in */
  transform: translateX(0); /* Move into the viewport */
}

.carousel-image {
  width: 90%;
  height: auto;
  filter: brightness(125%);
  transition: transform 1s ease; /* Smooth movement */
}

.category-card {
  position: relative;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1); /* Low-shaded bottom border */
  transition: border-color 0.3s ease;
}

.category-card::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker shade for hover */
  transition: width 0.4s ease;
}

.category-card:hover::after {
  width: 100%; /* Fill the border from left to right */
}

.con-like {
  --red: rgb(255, 50, 77);
  position: relative;
  width: 25px;
  height: 25px;
}

.con-like .like {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.con-like .checkmark {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-like .outline,
.con-like .filled {
  fill: var(--red);
  position: absolute;
}

.con-like .filled {
  animation: kfr-filled 0.5s;
  display: none;
}

.con-like .celebrate {
  position: absolute;
  animation: kfr-celebrate 0.5s;
  animation-fill-mode: forwards;
  display: none;
}

.con-like .poly {
  stroke: var(--red);
  fill: var(--red);
}

.con-like .like:checked ~ .checkmark .filled {
  display: block;
}

.con-like .like:checked ~ .checkmark .celebrate {
  display: block;
}

@keyframes kfr-filled {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}

.carousel {
  display: flex;
  gap: 0;
  animation: slide 5s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  33% {
    transform: translateX(-100%);
  }
  66% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee linear infinite;
}

.animate-marquee:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*  */

.slideImg {
  animation: slideFromTop 1s;
  animation-delay: 0.6s;
  transition: all 1s ease-in-out;
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-20%);
    opacity: 0;
    box-shadow: 2px 2px 10px 1px #00000063;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    box-shadow: none;
  }
}
