/* Fade-in and fade-out animations */
.transition-content {
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/* LeatherSelection.css */

.fade-effect {
  animation: fadeOutIn 0.5s ease-in-out;
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opac-1 {
  opacity: 1;
}
.opac-0 {
  opacity: 0;
}

.flip-card {
  perspective: 1000px;
  position: relative;
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.6s ease-in-out;
}

.flip-card-front {
  transform: rotateY(0deg);
  z-index: 2;
}

.flip-card-back {
  transform: rotateY(180deg);
  z-index: 1;
}

.flipped .flip-card-front {
  transform: rotateY(-180deg);
}

.flipped .flip-card-back {
  transform: rotateY(0deg);
}
