.dot-wave {
    display: inline-flex;
    gap: 4px;
    align-items: center;
  }
  
  .dot-wave span {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    animation: wave 1.2s infinite ease-in-out;
  }
  
  .dot-wave span:nth-child(1) {
    animation-delay: 0s;
  }
  .dot-wave span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot-wave span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes wave {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  