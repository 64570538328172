.admin-popup {
  position: fixed;
  background: #5d5d5d36;
  width: 100vw;
  height: 100%;
  top: 0px;
  left: 0px;
}

.admin-popup-nonloggedin {
  position: fixed;
  width: 100vw;
  top: 0px;
  left: 0px;
}

/* Wrapper for the sidebar content */
.sidebar-content {
  color: white;
  overflow: hidden;
  opacity: 0; /* Default hidden */
}

/* Animation class for slide effect */
.animate-slide {
  animation: slideIn 0.5s ease-in-out forwards;
}

/* Keyframes for sliding from top to bottom */
@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Menu option animation */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.admin-popup-animation {
  animation: slideUp 0.5s ease-in-out forwards;
}

/* Menu option animation */
@keyframes slideUpGenie {
  0% {
    right: -24px;
    bottom: -40px;
    scale: 0;
  }
  100% {
    right: 24px;
    scale: 100%;
  }
}

.admin-popup-animation-genie {
  animation: slideUpGenie 0.35s ease-in-out;
}

.admin-popup-animation-genie-loggedIn {
  animation: slideUpGenie2 0.5s ease-in-out forwards;
  overflow: scroll;
  transition: all 0.5s ease-in-out;
}


.dropdown-animation {
  animation: dropdownFadeIn 0.3s ease forwards;
}

@keyframes dropdownFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Product listing */
.carousel-container2 {
  overflow: hidden;
}

.carousel-slide2 {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item2 {
  flex: 0 0 100%;
  text-align: center;
}

.carousel-indicators2 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-indicators2 button {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}


@keyframes slideUpGenie2 {
  0% {
    transform: translateY(-360px);
    max-height: 0;
    max-width: 0;
    left: -50px;
    opacity: 0;
    scale: 0%;
  }
  100% {
    transform: translateY(0px);
    max-height: 1000px;
    max-width: 500px;
    top: 0px;
    left: 48px;
    opacity: 1;
    scale: 100%;
  }
}

.dropdown-animation {
  animation: dropdownFadeIn 0.3s ease forwards;
}

@keyframes dropdownFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.80);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* UMaterial Cost animation */
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.animate-marquee {
  animation: marquee 5s linear infinite;
}


/* No material found animation */
.loader {
  --path: #2f3545;
  --dot: #FEC23D;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}
