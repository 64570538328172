.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes popUpAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pop-up-animation {
  animation: popUpAnimation 0.3s ease-out;
}
@keyframes imageAnimation {
  from {
    scale: 102%;
    opacity: 0;
  }
  to {
    scale: 100%;
    opacity: 1;
  }
}

.image-animation {
  animation: imageAnimation 0.3s ease-out;
}

@keyframes userAnimation {
  0% {
    opacity: 1;
    scale: 0.1;
    top: -30px;
    left: -60px;
    z-index: 20;
  }
  100% {
    opacity: 1;
    scale: 1;
    top: 53.2px;
    left: -60px;
    z-index: 0;
  }
}

.user-animation {
  animation: userAnimation 0.3s ease-in-out;
}

.close-animation {
  animation: closeAnimation 0.3s ease-in-out;
}

@keyframes closeAnimation {
  0% {
    opacity: 1;
    scale: 1;
    top: 53.2px;
    left: -60px;
    z-index: 20;
  }
  100% {
    opacity: 1;
    scale: 0.1;
    top: -30px;
    left: -60px;
    z-index: 0;
  }
}

.after-header {
  position: relative;
  padding-top: 80px;
}

/* Media queries for larger screens (up to 576px) */
@media (max-width: 1200px) {
  .after-header {
    position: relative;
    padding-top: 80px;
  }
}
/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .after-header {
    position: relative;
    padding-top: 57px;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 991px) {
  .after-header {
    position: relative;
    padding-top: 80px;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (max-width: 991px) {
  .after-subheader {
    position: relative;
    padding-top: 40px;
  }
}

.opac-1 {
  opacity: 1;
}
.opac-0 {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0s ease-in;
}

.background-glass {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-glass-mobile {
  background-position: top;
}

/* Add keyframe animation to simulate forward motion */
@keyframes forwardMove {
  0% {
    transform: translateX(0); /* Start at original position */
  }
  50% {
    transform: translateX(5px); /* Move forward */
  }
  100% {
    transform: translateX(0); /* Return to original position */
  }
}

/* Add class for forward motion */
.forward-motion {
  animation: forwardMove 2s ease-out forwards infinite; /* Trigger the animation on click */
}

.zoomed-image {
  position: absolute;
  width: 200%;
  height: 200%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.zoom-viewer {
  position: absolute;
  overflow: hidden;
  display: none;
}

.relative:hover .zoom-viewer {
  display: block;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.rating input {
  display: none;
}

.rating label .svgOne {
  stroke: #ccc;
  fill: rgba(255, 217, 0, 0);
  transition: stroke 0.5s ease, fill 0.5s ease;
}

.rating label .svgTwo {
  position: absolute;
  top: -1px;
  fill: gold;
  stroke: rgba(255, 217, 0, 0);
  opacity: 0;
  transition: stroke 0.5s ease, fill 0.5s ease, opacity 0.5s ease;
}

.rating label {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  transition: all 0.5s ease;
}

/* Étoiles sélectionnées ou survolées */
.rating label:hover .svgOne,
.rating label:hover ~ label .svgOne {
  stroke: gold;
}

.rating input:checked ~ label .svgOne {
  stroke: #cccccc00;
}

.rating input:checked ~ label .svgTwo {
  opacity: 1;
  animation: displayStar 0.5s cubic-bezier(0.75, 0.41, 0.82, 1.2);
}

@keyframes displayStar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ombre {
  height: 8px;
  opacity: 0;
  transition: opacity 0.6s ease 0.2s;
}

/* Étoiles sélectionnées ou survolées */
.rating label:hover .ombre,
.rating label:hover ~ label .ombre {
  opacity: 0.3;
}

.rating input:checked ~ label .ombre {
  opacity: 1;
}

/* Add keyframe animation to simulate forward motion */
@keyframes fadeIn {
  0% {
    opacity: 0.9; /* Start at original position */
    transform: translateY(5);
    max-height: 0px;
  }
  100% {
    opacity: 1; /* Start at original position */
    transform: translateY(0);
    max-height: 240px;
  }
}

.fadein-animation {
  animation: fadeIn 0.3s ease-in-out forwards; /* Trigger the animation on click */
}

.blink-animation {
  animation: blinkIn 0.5s ease-in-out;
}

@keyframes blinkIn {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-section {
  animation: fadeSection 1s ease-out;
}

@keyframes fadeSection {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1; /* Start at original position */
    transform: translateY(0);
    height: 220px;
  }
  100% {
    opacity: 0; /* Start at original position */
    transform: translateY(5);
    height: 0;
  }
}

.fadeout-animation {
  animation: fadeOut 0.5s ease-in forwards; /* Trigger the animation on click */
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.loading-animation {
  animation: loading 5s ease-in-out; 
}

@keyframes scrollInfinite {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.reviews-container {
  animation: scrollInfinite 30s infinite; /* Slower scrolling effect */
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.custom-rounded-1 {
  border-bottom-left-radius: 200px;
  border-top-left-radius: 200px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 70px;
}

.custom-rounded-2 {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 70px;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
}
@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}
.animate-pulse2 {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
