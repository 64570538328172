#imageResize .cropper-view-box {
  border-radius: 50% !important;
  background-color: #00000000 !important;
}

#imageResize .cropper-line {
  display: none !important;
}
#imageResize .cropper-face {
  background-color: #ffffff00 !important;
}

#imageResize .cropper-modal {
  background-color: #8896b8 !important;
}
