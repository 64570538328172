.demo1-container {
  height: 100vh;
  position: fixed;
  background: #e6e6e69f;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo1-container-popup {
  height: 100%;
  position: fixed;
  background: #e6e6e69f;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* From Uiverse.io by Creatlydev */
.button-register {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
  background-color: #212121 ;
  color: #fff;
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #212121;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button-register:hover {
  background-color: #FACF52;
}

.button-register:hover .button__icon-wrapper {
  color: #FACF52;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button-register:hover .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button-register:hover .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}
