@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol[type="i"] {
  list-style-type: lower-roman;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.underline-animation {
  position: relative;
  border-bottom: 3px solid #ffc94a00; /* Low-shaded bottom border */
  transition: border-color 0.3s ease;
  text-decoration: none;
  color: #212121;
}

.underline-animation::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: #ffc94a; /* Darker shade for hover */
  transition: width 0.4s ease;
  color: #212121;
}

.underline-animation:hover::after {
  width: 100%; /* Fill the border from left to right */
  color: #212121;
}

.underline-animation-light {
  position: relative;
  border-bottom: 2px solid #f1f1f100; /* Low-shaded bottom border */
  transition: border-color 0.3s ease;
  text-decoration: none;
}

.underline-animation-light::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #ffc94a; /* Darker shade for hover */
  transition: width 0.4s ease;
}

.underline-animation-light:hover::after {
  width: 100%; /* Fill the border from left to right */
}

.underline-light {
  position: relative;
  border-bottom: 2px solid #f1f1f100; /* Low-shaded bottom border */
  transition: border-color 0.3s ease;
  text-decoration: none;
}

.underline-light::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #ffc94a; /* Darker shade for hover */
  transition: width 0.4s ease;
  width: 100%;
}

/* From Uiverse.io by guilhermeyohan */
.checkbox-wrapper-31:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
}

.checkbox-wrapper-31 .background {
  fill: #ccc;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  --appearance: none;
  --webkit-appearance: none;
  -webkit-appearance: none;
}

.checkbox-wrapper-31 input[type="checkbox"]:hover {
  cursor: pointer;
}

.checkbox-wrapper-31 svg .background {
  fill: gray;
}

.checkbox-wrapper-31 svg.checked-both .background {
  fill: #eebd2d;
}

.checkbox-wrapper-31 svg.checked-original .background {
  fill: #ef4444;
}

.checkbox-wrapper-31 svg.checked-altered .background {
  fill: #4ade80;
}

.checkbox-wrapper-31 input[type="checkbox"]:checked + svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 input[type="checkbox"]:checked + svg .check {
  stroke-dashoffset: 0;
}

.carousel-container {
  position: relative;
  height: 70vh;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.carousel-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: transform 0.7s ease-in-out;
}

.carousel-image {
  height: 70vh;
  width: 100%;
  object-fit: cover;
}
