/* Button animation */
.popup-container {
  height: 100vh;
  position: fixed;
  background: #8f8f8f9f;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupdelete-container {
  height: 100%;
  position: fixed;
  background: #8f8f8f9f;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slim Scrollbar css */
.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: orange; /* Dark color for thumb */
  border-radius: 10px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}



@media (min-width: 1560px) {
  .large-adjust {
    height: 60%;
  }
}

/* Popup animation */
@keyframes popUpAnimation {
    from {
      opacity: 0;
      scale: 50%;
    }
    to {
      opacity: 1;
      scale: 100%;
    }
  }
  
  .pop-up-animation {
    animation: popUpAnimation 0.4s ease-out;
  }

  /* open and close din */