/* slider popup */
.slider-container {
    height: 100%;
    position: absolute;
    right: 0;
    background: #e6e6e69f;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightToLeft {
    animation: rightToLeft 0.4s ease-in-out forwards;
  }
  
  /* Keyframes for sliding from top to bottom */
  @keyframes rightToLeft {
    0% {
      transform: translateX(100%);
      opacity: 1;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  /* Popup1 css  */
