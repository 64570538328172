.notification {
    position: relative;
    isolation: isolate;
    --gradient: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
    --color: #32a6ff;
  }
  
  .notification::before {
    content: "";
    position: absolute;
    inset: 1px;
    border-radius: 15px;
    background: #18181b;
    z-index: 2;
  }
  
  .notification::after {
    content: "";
    position: absolute;
    width: 4px;
    inset: 10px auto 10px 8px;
    border-radius: 2px;
    background: var(--gradient);
    transition: transform 300ms ease;
    z-index: 4;
  }
  
  .notification:hover::after {
    transform: translateX(2.4px);
  }
  
  .notiglow,
  .notiborderglow {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side at center, white, transparent);
    opacity: 0;
    transition: opacity 300ms ease;
  }
  
  .notiglow {
    z-index: 3;
  }
  
  .notiborderglow {
    z-index: 1;
  }
  
  .notification:hover .notiglow,
  .notification:hover .notiborderglow {
    opacity: 0.1;
  }
  
  /* AI Open Animation */
  @keyframes fadeInEquire {
    0% {
      opacity: 0.9; /* Start at original position */
      transform: translateY(100);
      height: 0;
    }
    100% {
      opacity: 1; /* Start at original position */
      transform: translateY(0);
      height: 100%;
    }
  }
  
  .fadeInEquire-animation {
    animation: fadeInEquire 0.5s ease-out forwards; /* Trigger the animation on click */
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 5s linear infinite; /* Adjust speed by changing '2s' */
  }
  
  