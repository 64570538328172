.sub-menu-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  min-width: 200px; /* Adjust the width as needed */
}

.menu-item:hover .sub-menu-container {
  display: flex;
}

.sub-menu-container a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: black;
}

.sub-menu-container a:hover {
  background-color: #f0f0f0;
}

.header-text {
  position: relative;
  border-bottom: 2px solid #ffc94a00; /* Low-shaded bottom border */
  transition: border-color 0.3s ease;
  text-decoration: none;
  color: #212121;
}

.header-text::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #ffc94a; /* Darker shade for hover */
  transition: width 0.4s ease;
  color: #212121;
}

.header-text:hover::after {
  width: 100%; /* Fill the border from left to right */
  color: #212121;
}

.input-wrapper {
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}

.input {
  border-style: none;
  height: 36px;
  width: 36px;
  outline: none;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  background-color: #3d3d3d;
  box-shadow: 0px 0px 3px #f3f3f3;
  padding-right: 37px;
  color: #1f1f1f;
}

.input::placeholder {
  color: #8f8f8f;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  outline: none;
  border-style: none;
  border-radius: 50%;
  pointer-events: painted;
  background-color: transparent;
  transition: 0.2s linear;
  stroke: #ffffff;
  stroke-width: 1.5px;
}

.icon:focus {
  stroke: #212121;
  stroke-width: 2px;
}

.icon:focus ~ .input,
.input:focus {
  box-shadow: none;
  width: 250px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 3px solid #ffc94a;
  transition: all 400ms;
  stroke: #212121;
}

.input-wrapper2 {
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}

.input2 {
  outline: none;
  box-shadow: 0px 0px 3px #f3f3f3;
  padding-right: 37px;
  color: #1f1f1f;
  box-shadow: none;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 3px solid #ffc94a;
  transition: all 400ms;
  stroke: #212121;
}

.input::placeholder {
  color: #8f8f8f;
}

.icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  cursor: pointer;
  width: 36px;
  height: 26px;
  outline: none;
  border-style: none;
  border-radius: 50%;
  pointer-events: painted;
  background-color: transparent;
  transition: 0.2s linear;
  stroke: #212121;
  stroke-width: 2px;
}

select {
  padding: 8px;
  font-size: 16px;
}

select option {
  display: flex;
  align-items: center;
}

select img {
  margin-right: 8px;
}

.flag {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.opac-1 {
  opacity: 1;
}
.opac-0 {
  opacity: 0;
}

/* From Uiverse.io by Gaurav-WebDev */
.button-sidebar {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: #333;
  transition: all 0.8s ease-in;
}



.type1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.5s ease-out;
  background-color: #ffefa5;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
}



.type1:hover::after {
  visibility: visible;
  transform: scale(100) ;
}

